import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../layout/Layout"
import Seo from "../layout/Seo"

const about = ({pageContext}) => {
    const {locale} = pageContext
    function Child({locale}){
        return(
                <>
                <Seo title="Error 404" description="Error 404" pathname="https:localhost"/>
            <section className="flex mt-9">
                <div className="flex-auto sm:flex-1"></div>
                <div className="flex sm:flex-1 justify-end sm:justify-start">
                    <div className="flex flex-col bg-primary p-2 md:p-5 rounded-br-xl md:rounded-br-medium  text-white w-7/12 md:w-8/12 xl:w-6/12 sm:ml-2 mr-4">
                        <span className="text-2xl leading-5 md:leading-7 lg:leading-normal md:text-3xl xl:text-4xl font-bold">
                            {locale.about.banner.title}
                        </span>
                        <span className="hidden md:block md:text-sm xl:text-xl font-bold mt-1">
                            {locale.about.banner.subtitle}
                        </span>
                    </div>
                </div>
            </section>

            <section className="p-5 md:p-2 font-serif text-secondary">
                <div className="flex flex-col md:flex-row self-center lg:max-w-6xl rounded-lg bg-white m-auto  mt-3 md:mt-6 text-center p-3">
                    <div className="w-full md:w-2/3 mt-3 p-1 md:p-8 text-justify">
                        <div className="flex flex-col">
                            <span className="text-primary uppercase font-bold text-xl font-serif">
                                {locale.about.banner.subtitle}
                            </span>
                            <span className="text-secondary font-light text-sm font-serif my-5">
                                {locale.about.section.text}
                                <br/>
                                <br/>
                                {locale.about.section.text2}
                                <br/>
                                <br/>
                                {locale.about.section.text3}
                                <br />
                                <br />
                                {locale.about.section.text4}
                            </span>

                            <span className="text-primary uppercase font-bold text-xl font-serif">
                                {locale.about.section.title2}
                            </span>
                            <span className="text-secondary font-light text-sm font-serif my-5">
                                {locale.about.section2.text}
                                <br/>
                                <br/>
                                {locale.about.section2.text2}
                            </span>
                        </div>
                    </div>

                    <div className="w-full md:w-1/3 bg-terciary mt-5 rounded-md" id={`about-sidebar${locale.quote.tag}`}>
                        <div className="flex mt-12 text-left">
                            <div className="w-1/3 mt-5 pl-2">
                                <StaticImage src="../images/alexander.png" alt="Alexander" layout="constrained"
                                    width={82}
                                    height={83}
                                    className="m-auto h-auto"
                                    imgClassName="h-auto m-auto"/>
                            </div>
                            <div className="w-2/3 pr-2 font-serif">
                                <span className=" uppercase font-bold text-sm">
                                    {locale.about.sidebar.worker.title}<br/>
                                </span>
                                <span className="text-xs">
                                    <strong>{locale.about.sidebar.worker.subtitle} </strong>.<br/>
                                    {locale.about.sidebar.worker.description}
                                </span>
                            </div>
                        </div>
                        <div className="flex mt-5 text-left">
                            <br />
                            <div className="w-1/3 mt-5 pl-2">
                                <StaticImage src="../images/carolina.png" alt="Alexander" layout="constrained"
                                    width={82}
                                    height={83}
                                    className="m-auto h-auto"
                                    imgClassName="h-auto m-auto"/>
                            </div>
                            <div className="w-2/3 pr-2 font-serif">
                                <span className=" uppercase font-bold text-sm">
                                    {locale.about.sidebar.worker2.title}<br/>
                                </span>
                                <span className="text-xs">
                                    <strong>{locale.about.sidebar.worker2.subtitle} </strong>.<br/>
                                    {locale.about.sidebar.worker2.description}
                                </span>
                            </div>
                        </div>
                        <div className="flex mt-5 text-left">
                            <br />
                            <div className="w-1/3 mt-5 pl-2">
                                <StaticImage src="../images/william.png" alt="Alexander" layout="constrained"
                                    width={82}
                                    height={83}
                                    className="m-auto h-auto"
                                    imgClassName="h-auto m-auto"/>
                            </div>
                            <div className="w-2/3 pr-2 font-serif">
                                <span className=" uppercase font-bold text-sm">
                                    {locale.about.sidebar.worker2.title}<br/>
                                </span>
                                <span className="text-xs">
                                    <strong>{locale.about.sidebar.worker2.subtitle} </strong>.<br/>
                                    {locale.about.sidebar.worker2.description}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quote-box mt-5 flex flox-col m-auto md:flex-row">
                   
                    <div className="flex-row md:flex w-full m:w-1/4 m-2">
                        <Link to={`/remodeling`}>
                            <StaticImage src="../images/home-2.png" alt="Obra Ya" layout="constrained"
                                width={271}
                                height={135}
                                className="m-auto  h-auto lg:w-full p-1 rounded-lg"
                                imgClassName="h-auto"/>
                        </Link>
                    </div>
                    <div className="flex-row md:flex w-full m:w-1/4 mb-2 mx-2 md:m-2">
                        <Link to={`/build`}>
                            <StaticImage src="../images/home-1.png" alt="Obra Ya" layout="constrained"
                                width={271}
                                height={135}
                                className="m-auto h-auto lg:w-full p-1 rounded-lg"
                                imgClassName="h-auto"/>
                        </Link>
                    </div>
                    <div className="flex-row md:flex w-full m:w-1/4 m-2">
                        <Link to={`/design`}>
                            <StaticImage src="../images/home-3.png" alt="Obra Ya" layout="constrained"
                                width={271}
                                height={135}
                                className="m-auto  h-auto lg:w-full p-1 rounded-lg"
                                imgClassName="h-auto"/>
                        </Link>
                    </div>
                    <div className="flex-row md:flex w-full m:w-1/4 m-2">
                        <Link to={`/planning`}>
                            <StaticImage src="../images/home-4.png" alt="Obra Ya" layout="constrained"
                                width={271}
                                height={135}
                                className="m-auto  h-auto lg:w-full p-1 rounded-lg"
                                imgClassName="h-auto"/>
                        </Link>
                    </div>
                </div>
            </section>
                </>
        )
    }
    return (
        <Layout locale={locale}
            background="bg-image-about">
             {props => <Child {...props} />}
        </Layout>
    )
}

export default about
